<template>
    <div class="account-page">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="my-5 pt-3">
                        
                        <div v-if="me">
                        
                            <h1>Moje konto</h1>
                            <div class="container">
                                <div class="row">
                                    <div class="col-12 col-md-4 mb-4">
                                        <div class="row">
                                            <div class="col-12 col-md-5">Imię:</div>
                                            <div class="col-12 col-md-7"><b>{{ me.name}}</b></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 col-md-5">Nazwisko:</div>
                                            <div class="col-12 col-md-7"><b>{{ me.surname }}</b></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 col-md-5">Email:</div>
                                            <div class="col-12 col-md-7"><b>{{ me.email }}</b></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 col-md-5">Telefon:</div>
                                            <div class="col-12 col-md-7"><b>{{ me.phone }}</b></div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-8">
                                        <router-link :to="{name: 'CustomerChangeData'}" class="mr-2"><button class="btn btn-primary" >Zmień dane</button></router-link>
                                        <br />
                                        <router-link :to="{name: 'CustomerChangePassword'}" ><button class="btn btn-secondary mt-3" >Zmień hasło</button></router-link>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-4 mb-4">
                                        <div class="mt-4" v-if="me.account_value > 0">
                                            Stan konta: <b class="ml-2">{{ me.account_value}} zł</b>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h1 class="mt-5">Adresy</h1>
                            <addressStep :hideSelect="true" />
                                
                            <h1 class="mt-5">Zamówienia aktualne</h1>
                            <div class="container">
                                <div class="row">
                                    <div class="col-12">
                                        <font-awesome-icon v-if="isLoading" :icon="['fas', 'spinner']" class="fa-spin mr-2 buttonFaSpin" />
                                        <div v-if="!isLoading">

                                            <b-table v-if="orders.length>0" stacked striped hover :items="orders" :fields="fields" :tbody-tr-class="rowClass" :bordered="true" sort-icon-left empty-filtered-text="Nie znaleziono" empty-text="Brak danych">

                                                <template #cell(place_order_time)="data">
                                                    {{ data.value | formatDateTime }}
                                                </template>

                                                <template #cell(type)="data">
                                                    {{ typeLabel( data.value ) }}
                                                </template>

                                                <template #cell(adress)="data">
                                                    ul. {{ data.item.address.street }} {{ data.item.address.number }}
                                                    <br />
                                                    {{ data.item.address.postcode }} {{ data.item.address.city }}
                                                </template>

                                                <template #cell(start_date)="data">
                                                    {{ data.value | formatDate }}
                                                </template>

                                                <template #cell(end_date)="data">
                                                    {{ data.value | formatDate }}
                                                </template>

                                                <template #cell(status)="data" class="danger">
                                                    {{ statusLabel( data.value ) }}
                                                </template>

                                                <template #cell(options)="data">
                                                    <router-link :to="{name: 'OrderPreview',  params: { orderId: data.item.id } }" ><button class="btn btn-secondary" >
                                                        Zobacz</button></router-link>
                                                </template>
                                                
                                            </b-table>
                                            <div v-if="orders.length==0" class="text-center bg-light m-2 p-3">brak aktywnych zamówień</div>
                                        </div>
                                        <div class="text-center">
                                            <router-link :to="{name: 'OrderList'}" ><button class="btn btn-secondary mt-3" >Zobacz wszystkie zamówienia</button></router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters} from "vuex";

import  { SCHEDULE_typeSubscription, SCHEDULE_typeOneTimePamyent, 
    SCHEDULE_statusNew, SCHEDULE_statusToPay, SCHEDULE_statusPaid, SCHEDULE_statusEnded, SCHEDULE_statusCanceled, } from "@/constants/constants.js";

import AddressStep from '@/components/order/AddressStep.vue';

export default {
    name: 'customerAccount',
    components: {
        AddressStep
    },
    data() {
        return {
            isLoading: true,
            orders:null,
            fields: [
                { label: 'Numer', key: 'id', sortable: false },
                { label: 'Data zamówienia', key: 'place_order_time', sortable: true },
                { label: 'Rodzaj', key: 'type', sortable: true },
                { label: 'Adres', key: 'adress', sortable: true },
                { label: 'Początek dostaw', key: 'start_date', sortable: true },
                { label: 'Opłacone do', key: 'end_date', sortable: true },
                { label: 'Kwota', key: 'sum_price', sortable: true },
                { label: 'Status', key: 'status', sortable: true },
                { label: '', key: 'options', sortable: false }
            ],
        }
    },
    mounted(){
        this.$store.dispatch('order/getOrderListActual').then( (data) => {
            this.orders = data;
            this.isLoading = false;
        });
    },
    computed: {
        ...mapGetters({ me : 'auth/me'}),
    },
    methods: {
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.status ===  SCHEDULE_statusToPay ) return 'table-warning';
        },
        typeLabel(type) {
            let label = '';
            switch( type ){
                case SCHEDULE_typeSubscription: { label = 'Abonament'; break; }
                case SCHEDULE_typeOneTimePamyent: { label = 'Jednorazowa'; break; }
            }
            return label;
        },
        statusLabel(status) {
            let label = '';
            switch( status ){
                case SCHEDULE_statusNew: { label = 'Koszyk'; break; }
                case SCHEDULE_statusToPay: { label = 'Nie opłacone'; break; }
                case SCHEDULE_statusPaid: { label = 'Aktywne'; break; }
                case SCHEDULE_statusEnded: { label = 'Zakończone'; break; }
                case SCHEDULE_statusCanceled: { label = 'Anulowane'; break; }
            }
            return label;
        }

    }
}
</script>
